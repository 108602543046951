
.form-weadding{
	padding-left: 1%;
	padding-right: 1%;
}

  .Scroll{
	height: 300px;
	width: 200px;
	border: 1px solid rgb(46, 73, 231);
	background: #ff0000;
	overflow-y: scroll;
  }
  .intro_title
  {
	  font-weight: 700;
	  color: #2d2c2c;
	  margin-top: 10px;
	  font-size: 50px;
	  font-family: 'Parisienne';
  }
.contact
{
	width: 100%;
	height: 100%;
	align-items: center;
}
.contact_background
{
	/* position: absolute; */
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
	background-size: 100% 115%;
	background-position:right;
	object-fit: cover;
	align-items: center;
	align-content: center;

}
.contact_title
{
	font-size: 30px;
	font-weight: 700;
	color: #FFFFFF;
	text-transform: uppercase;
}
.contact_form_container
{
	align-items: center;
	text-align-last:auto;
	/* align-content: center; */
	background: rgba(219, 154, 128, 0.8);	
	border-radius: 0.5%;	
	background-clip : padding-box;
	width : auto;
	height : auto;
	padding-bottom : 5%;
	padding-top : 5%;
	padding-left: 10%;
	padding-right: 10%;
}
.formlabel
{
	font-size:x-large;
	font-family: 'Parisienne';
	font-weight: 700;
	color: #fff;
}

.contact_form_name
{
	width: calc((100% - 30px) / 2);
	margin-right: 30px;
}
.contact_form_email
{
	width: calc((100% - 30px) / 2);
}
.contact_form_subject
{
	width: 100%;
	margin-top: 11px;
}
.contact_form_asistant
{
	width: 100%;
	margin-top: 11px;
}
.contact_form_message
{
	height: 126px;
	width: 100%;
	border: none;
	outline: none;
	margin-top: 19px;
	background: transparent;
	font-size: 12px;
	font-weight: 400;
	color: #FFFFFF;
	border-bottom: solid 2px #e1e1e1;
	padding-top: 11px;
}
.form_submit_button:enabled
{
	font-family: 'Montserrat';
	width: 200px;
	height: 50px;
	font-size: 15px;
	font-weight: 600;
	color: #fff;
	/* text-transform: uppercase; */
	padding-left: 20px;
	padding-right: 20px;
	background: #000;
	-webkit-transform: translateY(15px);
	-moz-transform: translateY(15px);
	-ms-transform: translateY(15px);
	-o-transform: translateY(15px);
	transform: translateY(15px);
	border-radius:20px;
	outline: none;
	margin-top: 10px;
	cursor: pointer;
}
.form_submit_button:disabled
{
	font-size: 13px;
	font-weight: 700;
	color: #000;
	text-transform: uppercase;
	padding-left: 20px;
	padding-right: 20px;
	background: rgb(253, 252, 252);
	-webkit-transform: translateY(15px);
	-moz-transform: translateY(15px);
	-ms-transform: translateY(15px);
	-o-transform: translateY(15px);
	transform: translateY(15px);
	border-radius: 20px;
	outline: none;
	margin-left: 15px ;
	margin-top: 32px;
	cursor: pointer;
}
.Form .Button:disabled
{
	font-size: 13px;
	font-weight: 700;
	color: #000;
	text-transform: uppercase;
	padding-left: 20px;
	padding-right: 20px;
	background: rgb(253, 252, 252);
	-webkit-transform: translateY(15px);
	-moz-transform: translateY(15px);
	-ms-transform: translateY(15px);
	-o-transform: translateY(15px);
	transform: translateY(15px);
	border-radius: 20px;
	outline: none;
	margin-left: 15px ;
	margin-top: 32px;
	cursor: pointer;
}
.form_submit_button:hover
{
	opacity: 0.8;
}
.input_field::-webkit-input-placeholder,
.text_field::-webkit-input-placeholder
{
	font-size: 12px !important;
	font-weight: 400 !important;
	color: #FFFFFF !important;
}
.input_field:-moz-placeholder,
.text_field:-moz-placeholder
{
	font-size: 12px !important;
	font-weight: 400 !important;
	color: #FFFFFF !important;
}
.input_field::-moz-placeholder,
.text_field::-moz-placeholder
{
	font-size: 12px !important;
	font-weight: 400 !important;
	color: #FFFFFF !important;
} 
.input_field:-ms-input-placeholder,
.text_field:-ms-input-placeholder
{ 
	font-size: 12px !important;
	font-weight: 400 !important;
	color: #FFFFFF !important;
}
.input_field::input-placeholder,
.text_field::input-placeholder
{
	font-size: 12px !important;
	font-weight: 400 !important;
	color: #FFFFFF !important;
}
.ShowDate
{
	font-size: large;
	color: #ff0000;
	font-weight: 500;
	/* text-align: center; */
	font-family: 'Courier New', Courier, monospace;

}