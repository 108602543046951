.intro_title
{
	font-weight: 700;
	color: #2d2c2c;
	padding-top: 20px;
	font-size: 60px;
	font-family: 'Parisienne';
	z-index: 10;
}
.bg-image
{
	width: 100%;
	height: 100vh;
}
.tam_imagen
{
	width: 100%;
	display: block;
	z-index: -1;
	background-position: center;
	background-size:cover;
	object-fit: cover;
	
}
.mask{
	background: linear-gradient(45deg, rgb(0,0,0), rgba(242, 224, 220) 100%);
}
.carousel_contenido
{
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	z-index: 10;
}
.carousel_2p 
{
	position: absolute;
	width: 100%;
	top: 30%;
	left: 50%;
	background-color: aqua;
	-webkit-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	z-index: 10;
}
.carousel_2p h1
{
	align-items: flex-end;
	text-align:center;
	font-family: 'Parisienne';
	margin-top: 2rem;
	font-size: 55px;
	top:20%;
	left: 50%;
	font-weight: 600!important;
	color:white;
}
.secondslide
{
	text-align:center;
	font-family: 'Parisienne';
	margin-top: 2rem;
	font-size: 55px;
	top:20%;
	left: 50%;
	font-weight: 600!important;
	color:white;
}
.thirdslide{
	text-align:center;
	font-family: 'Parisienne';
	margin-top: 2rem;
	font-size: 55px;
	top:20%;
	left: 50%;
	font-weight: 600!important;
	color:white;
}
.carousel_2p_inner 
{
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 10;
	-webkit-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}
.posicion_logo_order
{
	position: absolute;
	top: 30%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	z-index:10;
}
.contenido_logo_slider3 h1:first-child
{
	font-family: 'Garamond';
	margin-top: 1rem;
	font-size: 80px;
	font-weight: 600;
	color: white;
}
.contenido_logo_slider_krystal
{
	position: absolute;
	top: 35%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	z-index: 11;
}
.contenido_logo_slider_krystal2
{
	position: absolute;
	top: 35%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	z-index: 11;
}
.contenido_logo_slider3
{
	position: absolute;
	top: 35%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	z-index: 11;
}
.legenda_krystal h1
{
	width: 100%;
	height: 50%;
	font-family: 'Parisienne';
	font-weight: 700;
	font-size: 50px;
	margin-bottom: 300px;
	font-size: 60px;
	position: relative;
	top: 80%;
	left: 1%;
	color:#FFFFFF;
}
.posicion_logo_order
{
	position: absolute;;
	top: 12%;
	left: 50%;
	opacity: 0.7;
	-webkit-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	z-index: 11;
}
.carousel_contenido h1
{
	margin-bottom: 0px;
}

.carousel_contenido h1:first-child
{
	font-family: 'Parisienne';
	margin-top: -3rem;
	font-size: 80px;
	font-weight: 600;
	color: #FFFFFF;
}
.carousel_contenido h1:nth-child(2)
{

	font-family: 'Montserrat';
	font-size: 20px;
	font-weight: 600;
	color:#FFFFFF;
	margin-top: 10px;
}
.legend-order h1
{
    position: absolute;
	top: -550%;
	left: 0%;
	width: auto;
	font-family: 'Parisienne';
	transform: scale(1.05);
	color: #ffffff;
	font-weight: 400;
	font-size: 60px;
}
.legenda-order-express 
{
	position: absolute;
	top: 30%;
	left: 35%;
	width: auto;
	font-family: 'Parisienne';
	transform: scale(1.05);
	color: #FFFFFF;
	font-weight: 400;
	font-size: 60px;
}
.texto-krystal h2
{
    font-family: 'Parisienne';
    text-align: center;
    font-size: 30px;
    color:#FFFFFF;
}
.legend-krystal-slider2 h2
{
	display:flex;
	margin-bottom: 5%;
	font-family: 'Parisienne';
	text-align: center;
	align-content: flex-start;
	position:absolute;
	font-weight: 600;
	top:30%;
	font-weight: 400;
	font-size:40px;
	top:20%;

}

h2{
    font-family:'Parisienne';
    color: rgb(252, 250, 250);
}
h2:hover
{
    opacity: 0.9;
	transition-duration: 0.8s ;
	transform: scale(1.03);	
}
h1:hover{
	opacity: 0.8;
	transition-duration: 1s ;
	transform: scale(1.02);	
}
.logo-order img
{
    top:15%;
	margin-top: 2rem;
	text-align: center;
	height: 80px;
}
.logo-order-slider2 img
{
    top:80%;
	margin-top: 28rem;
	text-align:center;
	height: 100px;
}
.logo-order-slider2:hover
{
    opacity:0.8;
    transition-duration: 1s;
    transform: scale(1);

}
.logo-hotel img
{
	margin-top: 2rem;
	border-radius: 100%;
	height: 115px !important;
}
.logo-hotel-slider2 img
{
	text-align: center;
	margin-top:-11rem;
	border-radius: 100%;
	height: 220px!important;
	opacity: 0.8;
}
.hotel-slider img
{
	/* margin-top: 5rem; */
	margin-bottom: 2rem;
	border-radius: 100%;
	height: 200px !important;
	opacity: 0.7;
}
.hotel-slider_2 img
{
	/* margin-top: 5rem; */
	margin-bottom: 2rem;
	border-radius: 100%;
	height: 200px !important;
	opacity: 0.7;
}

/*                     RESPONSIVE                     */

/* 1. 1600px */
@media (max-width: 1600px)
{
    .bg-image
	{
		width:auto;
		height:100vh;
		display:flex;
	}	
	.logo-hotel img
	{
		margin-top: 2rem;
	    height: 100px !important;
	}
	.carousel_contenido h1:first-child
	{
	    margin-top: 3rem;
        font-size: 100px;

	}
	.carousel_contenido h1:nth-child(2)
	{

		font-family: 'Montserrat';
	    font-size: 25px;
	    font-weight: 600;
	    color:#FFFFFF;
	    margin-top: 10px;
	}
	.logo-order img 
	{
		height: 65px;
		margin-top: 3px!important;
	}
    .logo-order-slider2 img {
        margin-top: 22rem;

    }
    .logo-hotel-slider2 img {
        margin-bottom: 1rem;
    }
}
/* 2. 1440px */
@media (max-width: 1440px)
{

}

/* 3. 1280px */
@media (max-width: 1280px)
{

}

/* 4. 1199px */
@media (max-width: 1199px)
{

}

/* 5. 1024px */
@media (max-width: 1024px)
{

}

/* 6. 991px */
@media (max-width: 991px)
{

}

/* 7. 959px */
@media (max-width: 959px)
{
    .bg-image
	{
		width:auto;
		height:100vh;
		display:flex;
	}	
	.logo-hotel img
	{
		margin-top:5rem;
		border-radius: 100%;
		height: 15rem!important;
	}
	.carousel_contenido h1:first-child
	{
		font-family: 'Parisienne';
		margin-top: 5rem;
		font-size: 110px;
		font-weight: 600;
		color: #FFFFFF;
	}
	.carousel_contenido h1:nth-child(2)
	{

		font-family: 'Montserrat';
		font-size: 30px;
		font-weight: 600;
		color:#FFFFFF;
		margin-top: 10px;
	}
	.logo-order img 
	{
		height: 85px;
		margin-top: 3px!important;
	}
}

/* 8. 880px */
@media (max-width: 880px)
{

}

/* 9. 768px */
@media (max-width: 768px)
{
    .carousel_contenido h1:first-child {
        margin-top: 3rem;
        font-size: 85px;
    }
    .carousel_contenido h1:nth-child(2) {
        margin-top: 1rem;
        font-size: 20px;
    }
    .logo-hotel img {
        margin-top: 2rem;
        height: 9rem!important;
    }
    .logo-order img {
        height: 55px;
        margin-top: 1rem!important;
    }
    .logo-order-slider2 img {
        margin-top: 17rem;
        height: 60px;
    }
    .legend-order h1 {
        position: relative;
        font-weight: 400;
        font-size: 35px;
        margin-bottom: 15rem;
    }
    .texto-krystal h2
    {
        font-size: 25px;
        margin-bottom: 4rem;
    }
}

/* 10. 767px */
@media (max-width: 767px)
{

}


/* 11. 539px */
@media (max-width: 539px)
{
    .carousel_contenido h1:first-child {
        margin-top: 3rem;
        font-size: 80px;
        font-weight: 600;
    }
    .carousel_contenido h1:nth-child(2) {
        margin-top: 1rem;
        font-size: 20px;
        font-weight: 600;
    }
    .logo-hotel img {
        margin-top: 2rem;
        height: 10rem!important;
    }
    .logo-order img {
        height: 60px;
        margin-top: 2rem!important;
    }
    .hotel-slider_2 img {
        margin-bottom: 2rem;
        height: 180px !important;
    }
    .secondslide {
        margin-bottom: 5rem;
        font-size: 50px;
    }
    .hotel-slider img {
        margin-bottom: 2rem;
        height: 180px !important;
    }
    .thirdslide {
        margin-bottom: 9rem;
        font-size: 50px;
    }
    .logo-order-slider2 img {
        margin-top: 15rem;
        height: 75px;
    }
    .legend-order h1 {
        position: relative;
        font-size: 45px;
        margin-bottom: 13rem;
    }
    .logo-hotel-slider2 img {
        display: none;
    }
    .texto-krystal h2
    {
        font-size: 31px;
        margin-bottom: 2rem;
    }
}

/* 12. 479px */
@media (max-width: 479px)
{
    .carousel_contenido h1:first-child {
        margin-top: 3rem;
        font-size: 80px;
        font-weight: 600;
    }
    .carousel_contenido h1:nth-child(2) {
        margin-top: 1rem;
        font-size: 20px;
        font-weight: 600;
    }
    .logo-hotel img {
        margin-top: 2rem;
        height: 10rem!important;
    }
    .logo-order img {
        height: 60px;
        margin-top: 2rem!important;
    }
    .hotel-slider_2 img {
        margin-bottom: 2rem;
        height: 180px !important;
    }
    .secondslide {
        margin-bottom: 5rem;
        font-size: 50px;
    }
    .hotel-slider img {
        margin-bottom: 2rem;
        height: 180px !important;
    }
    .thirdslide {
        margin-bottom: 10rem;
        font-size: 50px;
    }
    .logo-order-slider2 img {
        margin-top: 15rem;
        height: 68px;
    }
    .legend-order h1 {
        position: relative;
        font-weight: 400;
        font-size: 38px;
        margin-bottom: 20rem;
    }
    .logo-hotel-slider2 img {
        display: none;
    }
    .texto-krystal h2
    {
        font-size: 32x;
        margin-bottom: 2rem;
    }
}

/* 13. 400px */
@media (max-width: 400px)
{
    .carousel_contenido h1:first-child {
        margin-top: 3rem;
        font-size: 80px;
        font-weight: 600;
    }
    .carousel_contenido h1:nth-child(2) {
        margin-top: 1rem;
        font-size: 20px;
        font-weight: 600;
    }
    .logo-hotel img {
        margin-top: 2rem;
        height: 10rem!important;
    }
    .logo-order img {
        height: 60px;
        margin-top: 2rem!important;
    }
    .hotel-slider_2 img {
        margin-bottom: 2rem;
        height: 150px !important;
    }
    .secondslide {
        margin-bottom: 3rem;
        font-size: 45px;
    }
    .hotel-slider img {
        margin-bottom: 2rem;
        height: 180px !important;
    }
    .thirdslide {
        margin-bottom: 8rem;
        font-size: 45px;
    }
    .logo-order-slider2 img {
        margin-top: 15rem;
        height: 65px;
    }
    .legend-order h1 {
        position: relative;
        font-weight: 400;
        font-size: 38px;
        margin-bottom: 10rem;
    }
    .logo-hotel-slider2 img {
        display: none;
    }
    .texto-krystal h2
    {
        font-size: 25.5px;
        margin-bottom: 3rem;
    }
}

/* 14. 320px */
@media (max-width: 360px)
{
    .carousel_contenido h1:first-child {
        margin-top: 3rem;
        font-size: 70px;
    }
    .carousel_contenido h1:nth-child(2) {
        margin-top: 1rem;
        font-size: 19px;
    }
    .logo-hotel img {
        margin-top: 2rem;
        height: 9rem!important;
    }
    .logo-order img {
        height: 55px;
        margin-top: 2rem!important;
    }
    .hotel-slider_2 img {
        margin-bottom: 2rem;
        height: 150px !important;
    }
    .secondslide {
        margin-bottom: 5rem;
        font-size: 48px;
    }
    .hotel-slider img {
        margin-bottom: 2rem;
        height: 150px !important;
    }
    .thirdslide {
        margin-bottom: 8rem;
        font-size: 48px;
    }
    .logo-order-slider2 img {
        margin-top: 17rem;
        height: 60px;
    }
    .legend-order h1 {
        position: relative;
        font-weight: 400;
        font-size: 35px;
        margin-bottom: 15rem;
    }
    .logo-hotel-slider2 img {
        display: none;
    }
    .texto-krystal h2
    {
        font-size: 25px;
        margin-bottom: 4rem;
    }
}

/* 15. 280px */
@media (max-width: 280px)
{
    .carousel_contenido h1:first-child {
        margin-top: 3rem;
        font-size: 60px;
    }
    .carousel_contenido h1:nth-child(2) {
        margin-top: 1rem;
        font-size: 15px;
    }
    .logo-hotel img {
        margin-top: 2rem;
        height: 7rem!important;
    }
    .logo-order img {
        height: 3rem;
        margin-top: 2rem!important;
    }
    .hotel-slider_2 img {
        margin-bottom: 2rem;
        height: 130px !important;
    }
    .secondslide {
        margin-bottom: 5rem;
        font-size: 38px;
    }
    .hotel-slider img {
        margin-bottom: 2rem;
        height: 130px !important;
    }
    .thirdslide {
        margin-bottom: 8rem;
        font-size: 38px;
    }
    .logo-order-slider2 img {
        margin-top: 15rem;
        height: 48px;
    }
    .legend-order h1 {
        position: relative;
        font-size: 35px;
        margin-bottom: 8rem;
    }
    .logo-hotel-slider2 img {
        display: none;
    }
    .texto-krystal h2
    {
        font-size: 22px;
        margin-bottom: 1rem;
    }
}
