@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800');
@font-face
{
	font-family: 'Parisienne';
	src: url('../fonts/Parisienne-Regular.ttf')  format('opentype');
}
*
{
	margin: 0;
	padding: 0;
	-webkit-font-smoothing: antialiased;
	-webkit-text-shadow: rgba(0,0,0,.01) 0 0 1px;
	text-shadow: rgba(0,0,0,.01) 0 0 1px;
}
.navbar
{
	background-color: rgba(0,0,0,.05)
}
body
{
	font-family: 'Open Sans', sans-serif;
	font-size: 14px;
	font-weight: 400;
	background: #FFFFFF;
	color: #a5a5a5;
}
div
{
	display: block;
	position: relative;
	-webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
ul
{
	list-style: none;
	margin-bottom: 0px;
}
p
{
	font-family: 'Open Sans', sans-serif;
	font-size: 14px;
	line-height: 2;
	font-weight: 400;
	color: #929191;
	-webkit-font-smoothing: antialiased;
	-webkit-text-shadow: rgba(0,0,0,.01) 0 0 1px;
	text-shadow: rgba(0,0,0,.01) 0 0 1px;
}
p a
{
	display: inline;
	position: relative;
	color: inherit;
	border-bottom: solid 1px #ffa07f;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
a, a:hover, a:visited, a:active, a:link
{
	text-decoration: none;
	-webkit-font-smoothing: antialiased;
	-webkit-text-shadow: rgba(0,0,0,.01) 0 0 1px;
	text-shadow: rgba(0,0,0,.01) 0 0 1px;
}
p a:active
{
	position: relative;
	color: #67b1ce;
}
p a:hover
{
	color: #FFFFFF;
	background: #67b1ce;
}
p a:hover::after
{
	opacity: 0.2;
}

h1{font-size: 48px;}
h2{font-size: 36px;}
h3{font-size: 24px;}
h4{font-size: 18px;}
h5{font-size: 14px;}
h1, h2, h3, h4, h5, h6
{
	font-family: 'Open Sans', sans-serif;
	-webkit-font-smoothing: antialiased;
	-webkit-text-shadow: rgba(0,0,0,.01) 0 0 1px;
	text-shadow: rgba(0,0,0,.01) 0 0 1px;
}

.form-control
{
	color: #67b1ce;
}
.form_embeded{
	box-align: center;
	display:flex ;
	
}
section
{
	display: block;
	position: relative;
	box-sizing: border-box;
}
.clear
{
	clear: both;
}
.clearfix::before, .clearfix::after
{
	content: "";
	display: table;
}
.clearfix::after
{
	clear: both;
}
.clearfix
{
	zoom: 1;
}
.float_left
{
	float: left;
}
.float_right
{
	float: right;
}
.trans_200
{
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.trans_300
{
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}
.trans_400
{
	-webkit-transition: all 400ms ease;
	-moz-transition: all 400ms ease;
	-ms-transition: all 400ms ease;
	-o-transition: all 400ms ease;
	transition: all 400ms ease;
}
.trans_500
{
	-webkit-transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	transition: all 500ms ease;
}
.fill_height
{
	height: 100%;
}
.super_container
{
	width: 100%;
	overflow: hidden;
}

.text_above
{
	position: absolute;
	top: 10px;
	left:10px;
}

.prlx_parent
{
	overflow: hidden;
}
.prlx
{
	height: 130% !important;
}
.nopadding
{
	padding: 0px !important;
}
.button
{
	display: inline-block;
	height: 53px;
	border-radius: 27px;
	overflow: hidden;
}
.button_bcg
{
	position: absolute;
	top: 0;
	left: 0;
	width: 200%;
	height: 100%;
	background: linear-gradient(to right, #C07965, #e29696,#BF7061);
	z-index: -1;
	-webkit-transition: all 400ms ease;
	-moz-transition: all 400ms ease;
	-ms-transition: all 400ms ease;
	-o-transition: all 400ms ease;
	transition: all 400ms ease;
}
.button:hover .button_bcg
{
	left: -100%;
}
.button a
{
	display: block;
	font-size: 13px;
	font-weight: 550;
	color: #fff;
	line-height: 53px;
	padding-left: 46px;
	padding-right: 46px;
	white-space: nowrap;
	z-index: 2;
}
.button a:hover
{
	color: #fff;
}
.button span
{
	display: inline-block;
	vertical-align: middle;
	width: 5.75px;
	height: 5.63px;
	background: #FFFFFF;
	border-radius: 50%;
	margin-right: 2px;
}
.button span:first-child
{
	margin-left: 10px;
}
.button span:nth-child(2)
{
	opacity: 0.6;
}
.button span:last-child
{
	opacity: 0.4;
	margin-right: 0px;
}
