.dropdown, .dropdown-center, .dropend, .dropstart, .dropup, .dropup-center
{
	font-family: "Montserrat";
	font-size: 12px;
	color: #fff;
}
.dropdown-toggle::after
{
	content: none !important;
}
.form-container
{
	font-weight: "Montserrat";
	position: flex;
	/* margin: 1rem; */
	font-size: 10px;
	font-weight: 550;
	/* width: 200%; */
}
.form-label
{
	font-family: 'Montserrat';
	font-size: 12px;
	color: #000;
	
}
.form-control
{
	font-size: 12px !important;
	color: #000 !important;
	
}
.header_submit_button
{
	font-family: 'Montserrat';
	width: 100%;
	height: 30px;
	font-size: 12px;
	color: #fff;
	background: #000;
	border-radius: 5px;
	margin-top: 5px;
}