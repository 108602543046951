/*            Header            */
.navbar
{
	background: #DB9A80;
	width: 100%;
}
.nav-link
{
	font-family: 'Montserrat' !important;
	color: #fff !important;
	font-size: 15px !important;
}
.nav-link:hover
{
	color: #fff;
}
.navbar-dark, 
.navbar-nav, 
.nav-link {
	font-size: 12px;
	color: #fff;
}
/*-------------------------------*/
.offcanvas-title {
	font-family: 'Parisienne';
	font-size: 24px !important;
	font-weight: 500;
	color: #fff;
}
.offcanvas,
.offcanvas.hiding,
.offcanvas.show,
.offcanvas.showing {
	width: 220px !important;
	background: #DB9A80 !important;
}
.B-fa-icons
{
	font-size: 20px;
	cursor: pointer;
	color: #fff;
	margin-right: 1rem;
}
/*-------------------------------------------*/
.menu_logo
{
	margin-bottom: 0.4rem;
}
.menu_logo a:hover 
{
	color: #fff;
}
.logo a
{
	font-family: 'Parisienne';
	font-size: 25px;
	color: #f4f4f8;
	font-weight: 500;
}
.logo a img
{
	display: inline-block;
	vertical-align: text-top;
	margin-right: 10px;
	-webkit-transform: translateY(2px);
	-moz-transform: translateY(2px);
	-ms-transform: translateY(2px);
	-o-transform: translateY(2px);
	transform: translateY(2px);
}
.main_nav_item
{
	position: relative;
	display: inline-block;
	margin-left: 15px;
	margin-right: 15px;
	font-family: 'Montserrat';
	font-size: 12px;
	color: #FFFFFF;
	/* text-transform: uppercase; */
	padding-bottom: 10px;
	padding-top: 10px;
	font-weight: -10px;
}
.main_nav_item:hover {
	color: #fff;
}
/*           Estatus            */
/* .estatus
{
	width: 100%;
	height: 100%;

}
.estatus-col
{
	background: #e5e5e7;
	height: 100vh;
}
.estatus-container
{
	margin: 1rem;
	margin-top: 2rem;
}
.B-fa-icons
{
	font-size: 20px;
	cursor: pointer;
	color: #DB9A80;
	user-select: none;
	margin-right: 1rem;
}
.nav 
{
	justify-content: center;
	font-family: 'Montserrat';
	font-size: 15px;
	font-weight: 450;
}
.nav a 
{
	color: #000;
}
.nav a:hover 
{
	color: #000;
}
.nav-pills {
	background-color: #e5e5e7;
	color:#000
}

.tab-content
{
	margin-top: 2rem;
	margin-right: 2rem;
} */
/*            Dashboard            */
.dashboard_item
{
	margin-bottom: 1.5rem;
	width: 100%;
	height: 250px;
}
.dashboard_item_background
{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center center;
	z-index: 1;
}
