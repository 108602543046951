.header
{
	position: fixed;
	width: 100%;
	background: rgba(219, 154, 128, 0.8);
	z-index: 12;
}
.header.scrolled
{
	background: rgba(219, 154, 128, 0.8);
}
.navbar-dark .navbar-nav .nav-link
{
	font-size: 12px;
	color: #fff;
}
.topBar
{
	width: 100%;
	height: 36px;
	background: #000000;  /*/ #ADE3EB  codigo color cancunlogo */
	-webkit-transition: all 400ms ease;
	-moz-transition: all 400ms ease;
	-ms-transition: all 400ms ease;
	-o-transition: all 400ms ease;
	transition: all 400ms ease;
}
.header.scrolled .topBar
{
	visibility: hidden;
	opacity: 0;
	height: 0px;
}
.user_box_link
{
	display: inline-block;
}
.user_box_link a
{
	display: block;
	font-size: 10px;
	line-height: 36px;
	font-weight: 600;
	color: #FFFFFF;
	text-transform: uppercase;
}
.user_box_link a:hover
{
	color: #67b1ce;
}
.user_box_login
{
	margin-right: 15px;
}
.user_box_login::after
{
	display: block;
	position: absolute;
	top: 15px;
	right: -9px;
	background: #FFFFFF;
	width: 1px;
	height: 9px;
}
.main_nav_col
{
	height: 80px;
	-webkit-transition: all 400ms ease;
	-moz-transition: all 400ms ease;
	-ms-transition: all 400ms ease;
	-o-transition: all 400ms ease;
	transition: all 400ms ease;
}
.header.scrolled .main_nav_col
{
	height: 100px;
}
.logo_container
{
	-webkit-transform: translateY(-6px);
	-moz-transform: translateY(-6px);
	-ms-transform: translateY(-6px);
	-o-transform: translateY(-6px);
	transform: translateY(-6px);
}
.header.scrolled .logo_container
{
	-webkit-transform: none;
	-moz-transform: none;
	-ms-transform: none;
	-o-transform: none;
	transform: none;
}
.logo a
{
	font-family: 'Parisienne';
	font-size: 25px;
	color: #f4f4f8;
	font-weight: 500;
	/* text-transform: uppercase; */
}
.logo a img
{
	display: inline-block;
	vertical-align: text-top;
	margin-right: 10px;
	-webkit-transform: translateY(2px);
	-moz-transform: translateY(2px);
	-ms-transform: translateY(2px);
	-o-transform: translateY(2px);
	transform: translateY(2px);
}
.main_nav_container
{
	opacity: 0.8;

}
.main_nav_item
{
	position: relative;
	display: inline-block;
	margin-left: 15px;
	margin-right: 15px;
	font-family: 'Montserrat';
	font-size: 14px;
	font-weight: 600;
	color: #FFFFFF;
	/* text-transform: uppercase; */
	padding-bottom: 10px;
	padding-top: 10px;
}
.main_nav_item a
{
	color: #fff;
}
.main_lang img
{
	height: 30px;
	width: 30px;
	margin-right: 1rem;
	background: transparent;
}
.main_button {
	background: transparent !important;
	border: none !important;
}

.main_nav_item::after
{
	display: block;
	position: absolute;
}
.main_nav_item:hover::after
{
	opacity: 1;
}
