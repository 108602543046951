.modal_table{
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 100;
    color: #000;
}

.modal_content {
    background: #fff;
    padding: 15px;
}

.modal_footer {
    text-align: center;
    margin-top: 10px;
}

.scroll_div_table {
    max-width: 95vw;
    max-height: 30rem;
    overflow: auto;
}

.modal_location{
    color: #000;
    list-style: none;
    cursor: pointer;
}
.modal_title
{
    font-family: 'Parisienne';
    font-weight: 700;
    color: #DB9A80;
    font-size: 50px;
    margin-bottom: 15px;
}
.modal_text
{
    font-family: 'Montserrat';
    font-size: 15px;
    color: #000;
    font-weight: 500;
}
.modal_imagen 
{
    width: 450px;
    height: 250px;
    display: block;
    z-index: -1;
    background-position: center;
    background-size: cover;
    object-fit: cover;
}
.btn_location
{
    background-color: #000;
    color: #fff;
    font-family: 'Montserrat';
    font-size: 15px;
    font-weight: 500;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
}
.modal_Carousel
{
    margin-bottom: 15px;
}

.size_slide {
    width: 450px;
    height: 250px;
}

@media (max-width: 468px) {
    .size_slide {
        width: 350px;
    }

}

@media (max-width: 367px) {
    .size_slide {
        width: 320px;
    }

}

@media (max-width: 335px) {
    .size_slide {
        width: 280px;
    }

}

@media (max-width: 295px) {
    .size_slide {
        width: 250px;
    }

}