.process
{
	width: 100%;
	padding-top: 1%;
	padding-bottom: 5%;
	margin-top: 2rem;
	margin-bottom: 2rem;
}
.process_title
{
	font-weight: 700;
	color: #2d2c2c;
	margin-top: 10px;
	font-size: 50px;
	font-family: 'Parisienne';
}	
.process_item
{
	margin-top: 1.5rem;
	width: 100%;
	height: 510px;
}
.process_item_overlay
{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 2;
	background: rgba(99, 80, 80, 0.377);
}

.process_item_background
{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
	z-index: 1;
}
.process_item_content
{
	width: 100%;
	height: 100%;
	z-index: 3;
}
.process_center h1
{
    margin-top: 2rem;
	font-family: 'Parisienne';
	font-size: 40px;
	font-weight: 400;
	color: #FFFFFF;
	letter-spacing: -0.05em;
    text-align: center;
}
.process-number h1
{
	font-family: 'Parisienne';
	margin-left: 45%;
	background: #DB9A80;
	border-radius: 100%;
	width: 40px;
	height: 40px;
	text-align: center;
}

.process_center ul
{
    margin-top: 1rem;
	margin-right: 1rem;
    font-family: 'Montserrat';
	font-size: 18px;
	font-weight: 400;
	color: #fff;
	letter-spacing: -0.05em;
}
.process_center p
{
    margin-top: 2rem;
    margin-left: 1rem;
	margin-right: 1rem;
    font-family: 'Montserrat';
	font-size: 18px;
	font-weight: 400;
	color: #fff;
	letter-spacing: -0.05em;
}

@media screen and (width: 280px) 
{
	.process_center h1
	{
		font-size: 30px;
	}
	.process_center ul
	{
		font-size: 15px;
	}
	.process_center p
	{
		font-size: 15px;
	}
}
	