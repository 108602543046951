.footer
{
	margin-top: 4rem;
	width: 100%;
	padding-top: 5%;
	padding-bottom: 104px;
	background: #DB9A80;
}
.footer_title
{
	font-family: 'Montserrat';
	font-size: 15px;
	font-weight: 700;
	color: #DEE0E3;
	text-transform: uppercase;
}
.footer_about
{
	padding-top: 67px;
}
.footer-logo img
{
	text-align: left;
	height: 30px;
	margin-bottom: 1rem;
}
.footer_about_text
{
	font-family: 'Montserrat';
	margin-top: 2rem;
	font-size: 14px;
	font-weight: 600;
	color: #FFFFFF;
}
.footer_social_item
{
	display: inline-block;
	width: 31px;
	height: 31px;
	border: solid 1px #000;
	border-radius: 50%;
	text-align: center;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
	margin-right: 9px;
}
.footer_social_item:last-child
{
	margin-right: 0px;
}
.footer_social_item a
{
	display: block;
	position: relative;
	width: 100%;
	height: 100%;
}
.footer_social_item a i
{
	display: block;
	position: relative;
	color: #FFFFFF;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	transform: translateY(-50%);
	font-size: 12px;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.footer_social_item:hover
{
	background: #000;
}
.footer_social_item:hover a i
{
	color: #DB9A80;
}
.footer_blog
{
	margin-top: 39px;
}
.footer_blog_item
{
	margin-bottom: 21px;
}
.footer_blog_item:last-child
{
	margin-bottom: 0px;
}
.footer_blog_image
{
	width: 60px;
	height: 60px;
	float: left;
}
.footer_blog_image img
{
	width: 100%;
}
.footer_blog_content
{
	padding-left: 81px;
}
.footer_blog_title
{
	font-family: 'Montserrat';
	margin-top: -4px;
	padding-left: 1px;
}
.footer_blog_title a
{
	font-size: 14px;
	font-weight: 400;
	color: #FFFFFF;
}
.footer_blog_title a:hover
{
	color: #fff;
}
.footer_blog_date
{
	font-family: 'Montserrat';
	font-size: 12px;
	font-weight: 400;
	color: #000;
	margin-top: 7px;
}
.footer_tags
{
	margin-top: 40px;
}
.tag_item
{
	height: 35px;
	float: left;
	margin-right: 9px;
	margin-bottom: 9px;
	border: solid 1px #000;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.tag_item:hover
{
	background: #000;
}
.tag_item a
{
	font-family: 'Montserrat';
	display: block;
	font-size: 12px;
	font-weight: 600;
	color: #FFFFFF;
	line-height: 33px;
	padding-left: 25px;
	padding-right: 25px;
}
.tag_item a:hover
{
	color: #DB9A80;
}
.tag_list ul 
{
	margin-left: -2rem;
}
.contact_info_list
{
	margin-top: 40px;
	margin-left: -2rem;
}
.contact_info_item
{
	margin-bottom: 22px;
}
.contact_info_icon
{
	width: 20px;
	height: 20px;
	margin-right: 10px;
}
.contact_info_icon img
{
	display: block;
	width: 100%;
}
.contact_info_text,
.contact_info_text a
{
	font-family: 'Montserrat';
	color: #FFFFFF;
	line-height: 2.14;
	margin-top: -4px;
}
.contact_info_item:hover .contact_info_text,
.contact_info_item:hover .contact_info_text a
{
	color: #fff;
}
.copyright
{
	background: #000;
}
.copyright_content
{
	height: 52px;
}
.copyright_content div
{
	font-size: 12px;
	font-weight: 600;
	color: #685677;
}
.footer_nav_container
{
	height: 52px;
}
.footer_nav_list
{
	font-size: 0px;
}
.footer_nav_item
{
	display: inline-block;
	margin-right: 40px;
}
.footer_nav_item a
{
	font-size: 12px;
	font-weight: 600;
	color: #685677;
	text-transform: uppercase;
	line-height: 2;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.footer_nav_item a:hover
{
	color: #FFFFFF;
}
.fa-icons{
	font-size: 1rem;
	cursor: pointer;
	color: #000;
	border-radius:8px;
	user-select: none;
	margin-right: 1rem;
}
.ubicacion-container {
	display: block;
	width: 100%;
	border: none;
	padding-top: 0.5%;
	padding-bottom: 0.5%;
	padding-right: 22%;
	padding-left: 22%;
}