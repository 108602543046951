.intro
{
	width: 100%;
	padding-top: 1%;
	padding-bottom: 5%;
	margin-top: 2rem;
	margin-bottom: 2rem;
}
.intro_title
{
	font-weight: 700;
	color: #2d2c2c;
	margin-top: 10px;
	font-size: 50px;
	font-family: 'Parisienne';
}
.intro_item
{
	margin-top: 1.5rem;
	width: 100%;
	height: 250px;
}
.intro_item_overlay
{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 2;
	background: rgba(110, 98, 98, 0.377);
}
.intro_item_background
{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
	z-index: 1;
}
.intro_item_content
{
	width: 100%;
	height: 100%;
	z-index: 3;
}
.intro_center h1
{
	font-family: 'Parisienne';
	font-size: 48px;
	font-weight: 500;
	color: #FFFFFF;
	letter-spacing: -0.05em;
}
.intro_center p
{
	font-size: 15px;
	font-weight: 400;
	color: #000;
	margin: 2rem;
	letter-spacing: -0.05em;
}
.about_video {
	margin-top: 8rem;
	border-radius: 40%;
}