/*
1. 1600px
2. 1440px
3. 1280px
4. 1199px
5. 1024px
6. 991px
7. 959px
8. 880px
9. 768px
10. 767px
11. 539px
12. 479px
13. 400px

******************************/

/************
1. 1600px
************/

@media only screen and (max-width: 1600px)
{
	
}

/************
2. 1440px
************/

@media only screen and (max-width: 1440px)
{
	
}

/************
3. 1380px
************/

@media only screen and (max-width: 1380px)
{
	
}

/************
3. 1280px
************/

@media only screen and (max-width: 1280px)
{
	
}

/************
4. 1199px
************/

@media only screen and (max-width: 1199px)
{
	.main_nav_container
	{
		margin-right: 121px;
	}
	.footer_blog_content
	{
		padding-left: 74px;
	}
}

/************
4. 1100px
************/

@media only screen and (max-width: 1100px)
{
	
}

/************
5. 1024px
************/

@media only screen and (max-width: 1024px)
{
	
}

/************
6. 991px
************/

@media only screen and (max-width: 991px)
{
	.main_nav_container
	{
		display: none;
	}
	.intro_col
	{
		margin-bottom: 10px;
	}
	.intro_col:last-child
	{
		margin-bottom: 0px;
	}
	.intro_item
	{
		height: 810px;
	}
	.copyright
	{
		padding-top: 30px;
		padding-bottom: 30px;
	}
	.copyright_content
	{
		height: auto;
		margin-top: 15px;
	}
	.footer_nav_container
	{
		height: auto;
	}
	.footer_nav_item
	{
		display: block;
	}
}

/************
7. 959px
************/

@media only screen and (max-width: 959px)
{
	
}

/************
8. 880px
************/

@media only screen and (max-width: 880px)
{
	
}

/************
9. 768px
************/

@media only screen and (max-width: 768px)
{
	
}

/************
10. 767px
************/

@media only screen and (max-width: 767px)
{
	.intro_item
	{
		height: 599px;
	}
	.offers_image_container
	{
		height: 524px;
	}
}

/************
11. 575px
************/

@media only screen and (max-width: 575px)
{
	p
	{
		font-size: 13px;
	}
	.top_bar
	{
		display: none;
	}
	.button
	{
		height: 44px;
		border-radius: 22px;
	}
	.button a
	{
		line-height: 44px;
		padding-left: 36px;
		padding-right: 36px;
	}
	.logo a img
	{
		width: 24px;
	}
	.logo a
	{
		font-size: 24px;
	}
	.main_nav_col
	{
		height: 60px;
	}
	.header.scrolled .main_nav_col
	{
		height: 60px;
	}
	.logo_container
	{
		-webkit-transform: none;
		-moz-transform: none;
		-ms-transform: none;
		-o-transform: none;
		transform: none;
	}
	.header.scrolled .content_search
	{
		-webkit-transform: translateY(2px);
		-moz-transform: translateY(2px);
		-ms-transform: translateY(2px);
		-o-transform: translateY(2px);
		transform: translateY(2px);
	}
	.logo a
	{
		font-size: 16px;
	}
	.logo a img
	{
		width: 16px;
		margin-right: 7px;
	}
	.intro_item
	{
		height: calc((100vw - 30px) / 0.85);
	}
	.section_title
	{
		font-size: 21px;
	}
	.contact_title
	{
		font-size: 24px;
	}
	.contact_form_name
	{
		width: 100%;
		margin-right: 0px;
	}
	.contact_form_email
	{
		width: 100%;
		margin-top: 11px;
	}
	.form_submit_button
	{
		line-height: 44px;
	}
	.footer_about_text
	{
		font-size: 13px;
	}
}

/************
11. 539px
************/

@media only screen and (max-width: 539px)
{
	
}

/************
12. 480px
************/

@media only screen and (max-width: 480px)
{
	
}

/************
13. 479px
************/

@media only screen and (max-width: 479px)
{
	h2{font-size: 24px;}
	p{font-size: 13px;}
	.intro_center h1
	{
		font-size: 36px;
	}
	.intro_text p
	{
		font-size: 16px;
	}
}

/************
14. 400px
************/

@media only screen and (max-width: 400px)
{
	
}

/*	responsive samsung galaxy fold			*/
/*				*/
@media only screen and (max-width: 280px){
	
}
